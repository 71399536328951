<template>
  <div class="page">
    <PageTitle :title="title" :routes="routes"></PageTitle>
    <router-view></router-view>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import PageTitle from '@/components/PageTitle'
export default {
  name: 'Brand',
  components: {
    PageTitle
  },
  computed: {
    title() {
      let name = '品牌列表'
      if (this.routes.length > 0) name = this.routes[this.routes.length - 1].name
      return name
    },
    ...mapState({
      routes: 'route'
    })
  },
  mounted() {
  },
  beforeDestroy() {
    this.$store.commit('changeRoute', null)
  },
  beforeRouteUpdate (to, from, next) {
    let path = to.path
    if (path.indexOf('/') === 0) path = path.substring(1)
    const name = to.query.id ? 'BrandDetail' : to.name
    const chineseName = {
      BrandAdd: '添加品牌信息',
      BrandDetail: '编辑品牌信息'
    }
    const toPage = {
      link: path,
      name: chineseName[name]
    }
    this.$store.commit('changeRoute', toPage)
    next()
  }
}
</script>
